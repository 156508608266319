import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styled from '@emotion/styled';
import {animation} from 'animate.css';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import useSliderImg from '../../hooks/useSliderImgs';
import {Link} from 'gatsby';
import IconsImg from '../IconsImg';

import useResponsive from '../../hooks/useResponsive';
const { device } = useResponsive();

const CarouselContainer = styled(Carousel)`
    padding-top:6rem 0;
    .carousel-inner{
    }
    .carousel-indicators{
        bottom:7rem;
        @media (max-width: 767px){
            bottom:5.5rem;
            z-index:998;
            
        }
    }
    .carouselItem{
        
        .carouselItem-info{
            padding: 6rem 10rem 10rem 10rem;
            min-height:100vh;
            display:grid;
            place-content:center;
            
            &:before,&:after{
                opacity:.05;
            }
            a:hover ul{
                background-color:hsl(230, 60%, 25%);;
            }  
            a:hover li{
                color:#ccc;
            }  
            .carouselItem-text{
                width:100%;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                h2{
                    font-size:4rem;
                    font-family:QuubMedium,sans-serif;
                    letter-spacing:2px;
                    color:hsl(333, 95%, 30%);
                    animation: fadeOut .1s;
                    animation-fill-mode:forwards;
                    line-height:1;
                    background-image: radial-gradient(#333 15%, transparent 16%),
                                    radial-gradient(#333 15%, transparent 16%),
                                    radial-gradient(#0f0f0f60 15%, transparent 20%),
                                    radial-gradient(#0f0f0f60 15%, transparent 20%);
                    background-repeat:repeat,repeat,repeat,repeat;
                    background-position:0px 0px, 8px 8px, 0px 1px, 8px 9px;
                    background-size:5px 5px, 5px 5px, 5px 5px, 5px 5px;
                    padding:1rem 2rem;
                    margin-top:-2rem !important;
                    width:100%;
                    text-align:center;
                    position:relative;
                    z-index:5;
                    &:before{
                        content:'';
                        position:absolute;
                        z-index:-1;
                        width:130%;
                        height: 70%;
                        background-color:hsl(0, 0%, 70%);
                        top:15%;
                        left:-15%;
                        clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
                        animation: fadeOut .1s;
                        animation-fill-mode:forwards;
                    }
                    @media ${device.mobileAll}{
                        padding:2rem;
                        font-size:3rem;
                    }
                    @media ${device.mobileSmall}{
                        font-size:2.5rem;
                    }
                }
                .carouselItem-img{
                    min-width:80%;
                    margin-top:2rem;
                    animation: fadeOut .1s;
                    animation-fill-mode:forwards;
                }
                
                p{
                    margin-top:1rem;
                    opacity:0;
                    transition: all .7s;
                    text-align:left;
                }
                @media ${device.mobileAll}{
                    p{
                        width:100%;
                        text-align:center;
                        font-size:1.4rem;
                    }
                    
                }
            }
            
            .carouselItem-pack{
                background-color:hsl(230, 60%, 35%);
                display:grid;
                grid-template-columns:repeat(3, 1fr);
                border-radius:3px;
                margin-top:2rem;
                opacity:0;
                transition:all ease 1s;
                padding:.5rem;
                li{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    padding:.5rem;
                    color:var(--txcolor);
                    transition:all .3s;
                    border-right:1px solid #7c7c7c;
                    &:last-of-type{
                        border:none;
                    }
                }
            }
            
        }
    }
    
    .carouselItem.active > .carouselItem-info{
        .carouselItem-img{
            animation: fadeIn .5s;
            animation-fill-mode:forwards;
        }
        h2{
            animation:lightSpeedInRight 1s;
            animation-fill-mode:forwards;
            &:before{
                animation: lightSpeedInLeft 1.5s;
                animation-fill-mode:forwards;
            }
        }
        p{
            opacity:1;
            transition-delay:1s;
        }
        .carouselItem-pack{
            animation:flipInX 1s 1.3s;
            animation-fill-mode:backwards;
            opacity:1;
        }
    }
`;

const ProductosNav = styled.div`
    height:6rem;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    margin-top:-6rem;
    z-index:997;
    .productosLink{
        background-color:hsl(345, 85%, 40%);
        color:var(--txcolor);
        height:100%;
        width:30%;
        clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
        display:flex; 
        justify-content:center;
        align-items:center;
        border-radius:10px;
        font-family:QuubBold, sans-serif;
        font-size:1.8rem;
        letter-spacing:1px;
        @media ${device.mobileSmall}{
            align-items:center;
            font-size:1.5rem;
            clip-path:none;
            border-radius:5px;
        }
        @media ${device.mobileAll} {
            position:relative;
            z-index:2;
        }
        &:hover{
            background-color:hsl(345, 70%, 50%);
        }
    }
    .resellers-icons img, .contact-media img{
        max-width:30px;
        margin:.5rem .3rem 0 .3rem;
        transition:all .5s;
        &:hover{
            transform:translateY(-5px);
        }
    }
    .contact-media{
        height:9rem;
        width:45%;
        background-color:hsl(0, 0%, 3%);
        position:absolute;
        left:0;
        bottom:0;
        z-index:999;
        clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
        display:flex;
        justify-content:center;
        flex-direction:column;
        padding-left:3rem;
        align-items:flex-start;
        @media (max-width: 767px){
            clip-path:none;
            width:50%;
            z-index:1;
            padding-left:1rem;
        }
        @media ${device.mobileSmall}{
            .media-icons img{
                width:20px;
            }
        }
        .media-icons{
            display:flex;
        }
    }
    .index-resellers{
        height:9rem;
        width:45%;
        background-color:hsl(0, 0%, 3%);
        position:absolute;
        right:0;
        bottom:0;
        z-index:999;
        clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
        display:flex;
        justify-content:center;
        flex-direction:column;
        padding-right:3rem;
        align-items:flex-end;
        @media (max-width: 767px){
            clip-path:none;
            width:50%;
            z-index:1;
            padding-right:1rem;
        }
        @media ${device.mobileSmall}{
            .resellers-icons img{
                width:20px;
            }
        }
    }
    h2{
        color:#ccc;
        font-size:1.5rem;
        padding-bottom:.5rem;
    }

`;

export default function CarouselHero() {
    
    const {slider1bg,slider2bg,slider3bg,pack1img,pack2img,pack3img} = useSliderImg();
    const {BrIcon,VeIcon,CoIcon,EcIcon,FacebookIcon,InstagramIcon,WhatsAppIcon} = IconsImg();

    return (
        <>
        <CarouselContainer fade={true} interval={90000} >
        
            <Carousel.Item className='carouselItem'>
                
                <BackgroundImage className='carouselItem-info' fluid={slider1bg}>
                    
                    <Link to='/' className='carouselItem-link'>

                        <div className='carouselItem-text'>
                            
                            <Image fluid={pack1img} className='carouselItem-img'/>
                            <h2>Pack Masa Muscular</h2>
                            <p>Aumenta tu masa muscular con este cocktail de anabolicos y testosterona.</p>
                        </div>
                        
                        <ul className='carouselItem-pack'>
                            <li>Stack</li>
                            <li>Sustanon</li>
                            <li>Boldenona</li>
                        </ul>
                    </Link>

                </BackgroundImage>
            </Carousel.Item>

            <Carousel.Item className='carouselItem'>
                
                <BackgroundImage className='carouselItem-info' fluid={slider2bg}>
                    
                    <Link to='/' className='carouselItem-link'>

                        <div className='carouselItem-text'>
                            
                            <Image fluid={pack2img} className='carouselItem-img'/>
                            <h2>Pack Definición Extrema</h2>
                            <p>Define tu cuerpo de forma natural con este cocktail de peptidicos.</p>
                        </div>
                        
                        <ul className='carouselItem-pack'>
                            <li>Fragmento</li>
                            <li>Ipamorelin</li>
                            <li>Cjc</li>
                        </ul>
                    </Link>

                </BackgroundImage>
            </Carousel.Item>

            <Carousel.Item className='carouselItem'>
                
                <BackgroundImage className='carouselItem-info' fluid={slider3bg}>
                    
                    <Link to='/' className='carouselItem-link'>

                        <div className='carouselItem-text'>
                            
                            <Image fluid={pack3img} className='carouselItem-img'/>
                            <h2>Pack Corte Muscular</h2>
                            <p>Define y corta tus musculos con este cocktail de anabolicos y testosterona.</p>
                        </div>
                        
                        <ul className='carouselItem-pack'>
                            <li>Primobolam</li>
                            <li>Winstrol</li>
                            <li>Masteron</li>
                        </ul>
                    </Link>

                </BackgroundImage>
            </Carousel.Item>
                   
        </CarouselContainer>

        <ProductosNav>

            <div className='contact-media'>
                <h2> Contactanos </h2>
                <div className='media-icons'>
                    <a href='https://www.facebook.com/profile.php?id=100019604204995' target="_blank" rel="noreferrer"> <img src={FacebookIcon} alt='SGL Facebook' /> </a>
                    <a href='https://www.instagram.com/sglseriegold/' target="_blank" rel="noreferrer"> <img src={InstagramIcon} alt='SGL Instagram' /> </a>
                    <a href='https://api.whatsapp.com/send?phone=584121099414&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL WhatsApp' /> </a>
                </div>
            </div>

            <Link className='productosLink' to='/productos-sgl/'>Productos</Link>

            <div className='index-resellers'>
                <h2> Resellers </h2>
                <Link to='/resellers/' className='resellers-icons'>
                    <img src={BrIcon} alt='Brasil Icon' /> 
                    <img src={VeIcon} alt='Venezuela Icon' /> 
                    <img src={CoIcon} alt='Colombia Icon' /> 
                    <img src={EcIcon} alt='Ecuador Icon' /> 
                </Link>
            </div>
        
        </ProductosNav>

        </>
    );
}
