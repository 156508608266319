import React from "react";
import styled from '@emotion/styled';
import Layout from "../components/layout"
import CarouselHero from '../components/indexComponents/CarouselHero';
import QuienesSomos from '../components/indexComponents/QuienesSomos';


const IndexContentCSS = styled.div`
  margin-top:-6rem;
  background-color:var(--bgcolor);
  
`;

export default function IndexPage() {


  return (
    <Layout>
        <IndexContentCSS>

          <CarouselHero/>

          <QuienesSomos/>
          
        </IndexContentCSS>
    </Layout>
  );
}
