import React from 'react';
import styled from '@emotion/styled';
import Logo from '../../images/Logo.png';

const AboutSection = styled.div`
    min-height:75vh;
    padding:3rem;
    position:relative;
    z-index: 555;
    display:flex;
    align-items:center;
    justify-content:center;
    &::after{
        content:'';
        height:100%;
        width:100%;
        display:block;
        position:absolute;
        left:0;
        top:0;
        z-index:-2;
        background-image: url(${Logo}),
                        radial-gradient(#555555 15%, transparent 16%),
                        radial-gradient(#555555 15%, transparent 16%),
                        radial-gradient(#ffffff80 15%, transparent 20%),
                        radial-gradient(#ffffff80 15%, transparent 20%);
        background-repeat:no-repeat, repeat,repeat,repeat,repeat;
        background-position: 50% 0, 0px 0px, 8px 8px, 0px 1px, 8px 9px;
        background-size: contain,16px 16px, 16px 16px, 16px 16px, 16px 16px;
        opacity: .05;
    }
    .about-info{
        width:50%;
        position:relative;
        z-index:777;
        @media (max-width: 460px){
            width:100%;
        }
        @media (max-width: 767px){
            width:80%;
        }
        h2{
            margin-bottom:2rem !important;
            font-size:2.8rem !important;
            color:var(--txcolor);
            font-family:QuubMedium,sans-serif;
            letter-spacing:1px;
            position:relative;
            &:before{
            content:'';
            height:3px;
            width:50%;
            background-color:var(--s1color);
            position: absolute;
            left:0;
            bottom:-.5rem;
            clip-path: polygon(0 0, 0 100%, 100% 50%);
            }
        }
    }
    
`;

export default function QuienesSomos() {
    return (
        <AboutSection>
            <div className='about-info'>
                <h2>Quienes Somos</h2>
                <p>STRONG LAB Serie Gold (SGL SERIE GOLD) es un laboratorio Dominicano encargado en la elaboración de productos de calidad para atletas de alto rendimiento que quieren mejorar su condición física y mental al lograr los objetivos deseados con un Producto de primera calidad.</p>
            </div>
            <div>
                
            </div>
        </AboutSection>
    )
}
