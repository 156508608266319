import {useStaticQuery} from 'gatsby';

export default function useSliderImgs() {
    const {img1,img2,img3,pack1,pack2,pack3} = useStaticQuery(graphql`
        query {
            pack1: file(relativePath: { eq: "slider/Pack1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            pack2: file(relativePath: { eq: "slider/Pack2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            pack3: file(relativePath: { eq: "slider/Pack3.png" }) {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            img1: file(relativePath: { eq: "slider/img1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            img2: file(relativePath: { eq: "slider/img2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            img3: file(relativePath: { eq: "slider/img3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
        }
    `);
    
    const slider1bg = img1.childImageSharp.fluid;
    const slider2bg = img2.childImageSharp.fluid;
    const slider3bg = img3.childImageSharp.fluid;
    const pack1img = pack1.childImageSharp.fluid;
    const pack2img = pack2.childImageSharp.fluid;
    const pack3img = pack3.childImageSharp.fluid;

    return {slider1bg,slider2bg,slider3bg,pack1img,pack2img,pack3img}
}
